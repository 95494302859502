
const App = () => {
  return (
   <div>
    <h1>Under construction ...</h1>
    <p>Coming soon</p>
   </div>
  );
}

export default App;
